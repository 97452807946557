.calloutbox-modal {
  width: 544px !important;
  height: 673px;
  .btn-div {
    display: flex;
    width: 266px;

    Button {
      border: 1px solid #00be94;
      width: 200px;
      font-size: 16px;
    }
  }

  .callout-input-num {
    border-radius: 4px !important;
  }

  .delete-btn {
    width: 10px;
    height: 10px;
    &.disabled {
      background-color: transparent;
    }
  }

  .preset-select {
    font-size: 16px;
    width: 100%;
    color: black;

    &.preset-select-gray {
      color: gray;
    }
  }

  .border-settings {
    margin-top: 10px;
    font-size: 16px;
    .slider-label {
      margin-right:10px;
    }
    .slider-value {
      font-weight: 600;
      margin-left:10px;
    }

    .border-weight-row {
      margin-bottom: 10px;
    }
  }


  .preview-div {
    .preview-title {
      font-size: 18px;
      font-weight: 600;
    }
    .preview-tile-container {
      padding: 20px;
      margin-top: 5px;
      margin-bottom: 15px;
      overflow: hidden;
      border: 1px solid #d9d9d9;

      .preview-tile {
        width: 100%;
        height: 100px;
      }
    }
  }

  .cl-properties {
    font-size: 16px;

    .cl-label {
      margin-left: 5px;
    }

    .cl-label-style {
      margin-left: 5px;
      margin-right: 5px;
    }

    .callout-property-cl{
      display: flex;
      align-items: center;
    }

    .style-selector {
      width: 134px;
    }
  }

  .apply-btn {
    font-size: 16px;
  }
  .preset-text-div {
    .at-form-input {
      font-size: 16px;
      width: 266px;
    }
  }
}

.callout-block-action {
  position: absolute;
  top: 10px;
  right: 15px;

  .callout-block-action-btn {
    height: 2em;
    width: 2em;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
