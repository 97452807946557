$default-bg-color: #ffffff;
$primary-bg-color: #E8F2FD;
$default-area-bg-color: #e8f2fd;
$btn-bg-color: #171d21;
$btn-new-bg-color: #00be95;
$default-gray-bg-color: #f7f7f7;
$slick-bg-btn-color: #3568ba;
$slick-bg-color: #1890ff;
$lighter-slick-bg-color: #8cbcf4;
$common-theme-bg-color: #EBEBEB;
$background-blue: rgb(231, 241, 253);

$dark-gray-bg-color: rgb(128, 128, 128);
$darker-gray-color1: #485460;
$white-bg-color1: rgb(245, 245, 245);
$darker-gray-color: #181d21;
$lighter-gray-color1: #d6d6d6;
$lighter-gray-color: #efefef;
$light-gray-color: #ecebec;
$light-gray-color1: #f3f3f3;
$light-gray-color2: #cccccc;
$card-box-shadow-color: #3568BA33;
$black-bg-with-alpha: #3568ba1a;
$black-bg-color1: #262e33;
$black-bg-color: #000000;
$extra-box-shadow-color: #0000000F;
$common-border-color: #ECECEC;
$bg-color-transparent: transparent;
$blue-bg-tint: #F3F6FB;

$subtext-color: #A2A5A6;
$subheading-text-color: #171D21;

$spacing-Z: 0;
$spacing-XS: 1rem;
$spacing-S: 2rem;
$spacing-M: 3rem;
$spacing-L: 4rem;
$spacing-XL: 5rem;
$spacing-XXL: 6rem;

$font-size: 15px;
$font-size-xs: .7rem;
$font-size-sm: .8rem;
$font-size-lg: 1.25rem;
$font-size-xl: 1.5rem;


$font-bold: bold;
$font-semi-bold: 600;

$default-padding: 0.8em 1.4em;
$default-box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);


// button-sizes
$btn-size-small: 32px;
$btn-size-medium: 40px;
$btn-size-large: 48px;
$btn-size-xlarge: 56px;

// modal-sizes
$modal-width-dialog: 520px;


// UI-Revamp color variables 

//primary-colors

$primary-bg-blue: #3568BA;
$primary-bg-blue-tint: #83a3d5;
$primary-bg-mint: #00BE95;
$primary-bg-mint-tint: #33cbaa;
$primary-bg-mint-tint-2:#E5F9F4;
$primary-bg-grey: #F3F3F3;

//secondary-colors

$secondary-bg-black: #171D21;
$secondary-bg-blue: #8cbcf4;
$secondary-bg-red: #fe2121;
$secondary-bg-grey: #A2A5A6;


//status-colors

$status-success: #00be95;
$status-error: #fe2121;
$status-information: #21b8e8;
$status-alert: #ffb302;
$status-disabled: #DBDCDD;

//button-colors

$primary-btn-blue-hover: #2a5394;
$primary-btn-blue-clicked: #1f3e6f;

$secondary-btn-blue-hover: #F1F5FC;
$secondary-btn-blue-clicked: #E7EDF8;

$primary-btn-green-hover: #00AB86;    
$primary-btn-green-clicked: #007259;

$primary-btn-grey-hover: #e8e8e8;

$secondary-btn-green-hover: #F2FCFA;
$secondary-btn-green-clicked: #E5F9F4;

$primary-btn-disabled: #e8e8e9;
$secondary-btn-disabled: #ffffff;
$disabled-btn-border: #d7d8d9;

$primary-text-black: #171D21;

//background-colors
$primary-background: #F1F5FC;

//spacing 

$space-xxs:  4px;
$space-xs:   8px;
$space-sm:   12px;
$space-md:   16px;
$space-lg:   24px;
$space-xl:   32px;
$space-xxl:  40px;
$space-xxxl: 64px;
$space-4xl:  80px;
$space-5xl:  160px;


