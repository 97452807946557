$header-height: 58px;

.pdf-exporter-container {
    z-index: 10;
    position: fixed;
    bottom: 0;
    right: 65px;
    max-width: 310px;
    max-height: 370px;
    background-color: #ffffff;
    width: 100%;
    height: 100%;
    box-shadow: 0px 0px 7px rgba(0, 0, 0, 0.08);
    overflow: hidden;
    border-radius: 5px 5px 0 0;

    display: grid;
    grid-template-rows: max-content auto;

    &.minimized {
        animation: pdf-exp-minimized-animation 0.3s forwards;
    }

    &.expanded {
        animation: pdf-exp-expanded-animation 0.3s forwards;
    }

    .header-container {
        height: $header-height;
        background-color: #171d21;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .left {
            display: grid;
            align-items: center;
            padding: 0 0 0 1rem;
            .text {
                color: #ffffff;
                font-size: 1.4rem;
            }
        }
        .right {
            display: grid;
            align-items: center;
            grid-template-columns: repeat(2, max-content);
            gap: 0 1rem;
            padding: 0 1rem 0 0;

            .window-state-button-icon {
                svg {
                    transition: 0.3s;
                }
            }
        }
    }

    .export-item-list-container {
        overflow-y: auto;
        position: relative;
    }
}

@keyframes pdf-exp-minimized-animation {
    0% {
        height: 370px;
    }
    100% {
        height: $header-height;
    }
}

@keyframes pdf-exp-expanded-animation {
    0% {
        height: $header-height;
    }
    100% {
        height: 370px;
    }
}

.export-item-container {
    border-bottom: 1px solid #e7e7e7;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: 1.5rem 1rem;
    
    .left {
        .text-container {
            .name {
                word-break: break-all;
            }
            .time-description {
                font-size: 0.9rem;
                color: #a5a5a5;
            }
        }
    }
    .right {
        padding-left: 1rem;
        .inner-progress-button {
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            .download-cancel-button {
                background-color: transparent;
                color: #a5a5a5;
                display: none;

                &.loading {
                }
            }

            &:hover > .download-cancel-button {
                display: block !important;

                &.loading {
                    display: block;
                }
            }

            &:hover > .loading-indicator {
                display: none;
            }

            .download-button {
                background-color: transparent;
                color: #a5a5a5;
            }
        }
    }
}
