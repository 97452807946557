.slate-page-break {
  &.selected {
    outline: 2px solid #2da6ea;
  }
  border-radius: 4px;
  margin: 0.4rem 0rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px solid whitesmoke;
}

.slate-page-break-icon-cover{
  display: flex;
  justify-content: center;
}