.calendar-heatmap-container {
  width: 100%;
  margin-top: 1em;

  .calendar-row {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-gap: 5px;
  }

  .calendar-row:not(.calendar-row-item:nth-child(1)) {
    margin-top: 5px;
  }

  .calendar-header-item.calendar-row-item {
    color: rgba(23, 29, 33, 0.6);
    font-size: 12px;
    font-weight: 500;
  }

  .calendar-row-item {
    text-align: center;
  }

  .calendar-row-item:not(.calendar-header-item.calendar-row-item, .calendar-row-item.null-item) {
    background: rgba(23, 29, 33, 0.06);
    border-radius: 2px;
    min-height: 8px;

    &.calendar-cell-green {
        background: rgba(0, 190, 149, 0.5) !important;
      }

    &.calendar-cell-yellow {
      background: rgba(235, 210, 85, 0.5) !important;
    }
  }
}


