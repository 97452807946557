.font-gallery-btn-wrapper {
  display: flex;
  flex-direction: column;
  background: #3568BA 0% 0% no-repeat padding-box;
  border-radius: 4px;
  opacity: 1;
}

.font-tabs {
  width: 100%;
  flex: auto;
  padding: 0.5em;

  .font-card-outer {
    padding: .5em;
  }

  .font-listing-wrapper {
    width: 100%;
    background: #F7F7F7 0% 0% no-repeat padding-box;
    border-radius: 4px;
    opacity: 1;
  }

  .font-fav-tabs-inner {
    display: flex;
    flex-flow: row wrap;
    min-width: 0;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: stretch;
    justify-content: space-evenly;
    align-items: flex-start;
  }

  .font-card {
    box-sizing: border-box;
    margin: 0;
    padding: .5em 1em;
    height: 115px;
    border: 1px solid whitesmoke;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    font-variant: tabular-nums;
    line-height: 1.5715;
    list-style: none;
    font-feature-settings: 'tnum';
    position: relative;
    background: #fff;
    border-radius: 4px;

    .font-card-title {
      font-size: 24px;
      line-height: 33px;
      text-align: left;
      letter-spacing: 0px;
      color: #171D21;
      opacity: 1;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .font-card-category {
      font-size: 14px;
      line-height: 19px;
    }

    .font-card-action-item {
      position: absolute;
      width: 24px;
      height: 89px;
      bottom: 12px;
      right: 19px;
    }

    .font-fav-btn {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 30px;
      height: 30px;
      background: #FFFFFF 0% 0% no-repeat padding-box;
      box-shadow: 0px 1px 12px #00000014;
      border: 1px solid #DBDCDD;
      border-radius: 100px;
      opacity: 1;

      &:hover, :focus {
        background-color: whitesmoke;
        color: black;
      }
    }
  }

  .font-card-content {
    max-width: 90%;
  }

  .font-card-variant {
    display: inline-block;
    flex-wrap: nowrap;
    border-radius: 2px;
    opacity: 1;
    margin: 4px 0px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 100%;

    .font-variant-tag {
      background: #E7EDF8 0% 0% no-repeat padding-box;
      border-radius: 2px;
      opacity: 1;
      text-align: center;
      letter-spacing: 0px;
      color: #3568BA;
    }
  }

  .font-remove-btn {
    padding: .25em 0.5em;
    height: 2em;
    border: 1px solid red;
  }
}

.font-filter-heading {
  margin-bottom: .5em;

  .ant-checkbox-group {
    padding-left: 8px;

    .ant-checkbox-group-item {
      margin-top: 5px;
      margin-bottom: 5px;

      .ant-checkbox-inner {
        border-radius: 5px;
      }

      .ant-checkbox-checked {
        &:hover {
          .ant-checkbox-inner {
            border-radius: 5px;
          }
        }
      }
    }
  }
}

.select-container .ant-select {
  display: block;
}

.select-container {
  .ant-checkbox-group {
    background-color: #3568BA;
  }
}

.font-listing-header {
  padding: 0 .25em;
  margin-bottom: .5em;
  width: 500px;
}

.font-search {
  background-color: whitesmoke;
  border-radius: 10px;
  
  .ant-input-affix-wrapper, .ant-input-wrapper, input, .ant-input-group-addon, button{
    background-color: transparent;
  }
}

.font-gallery-tab-wrapper{
  height: 62vh;
  background-color: #F7F7F7;

  .font-gallery-tab-inner{
    max-height: 62vh;
    min-height: 300px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    overflow-y: auto;
  }
}