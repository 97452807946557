.profiles-icon {
	background-color: transparent;
	border: none;
	box-shadow: none;
	color: black;
	padding: 0.5px !important;
}

.prof-select {
	margin-bottom: 25px;
}

.sm-item {
	margin-bottom: 20px !important;
}

.sm-dragged {
	.ant-input-group-addon {
		background-color: #3568ba !important;
	}
	.sm-item-name{
		color: whitesmoke;
	}
	.sm-icon-deleteIcon{
		color: whitesmoke !important;
	}
}

.sm-input {
	input {padding: 0.75rem;}

	.ant-input-group-addon:first-child{
		width: 100px;
	}
}

.draggable-container {
	max-height: 30vh;
	width: 100%;
	overflow-y: auto;
}

.prof-details {
	margin-top: 35px;
	margin-bottom: 20px;

	h3 {
		text-align: center;
		margin-bottom: 8px;
	}

	div {
		display: flex;
		padding: 0 20px 0 20px;
	}
}

.create-prof-div{
	label{
		font-weight: 600;
	}
}

.all-prof {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px 15px;
	// box-shadow: rgba(149, 157, 165, 0.2) 9px 8px 24px;
	margin-bottom: 10px;
	border-radius: 5px;
	border: 1px solid #DBDCDD;
	background-color: #F3F3F3;

	&.selected {
		border: 1px solid #3568ba;
		background-color: #3568ba;
	}
}

.profile_name {
	font-weight: 600;
	&.selected {
		color: white;
	}
}

.profile-list-action-btns {
	display: flex;
}

.create-prof-btn-div {
	display: flex;
	justify-content: flex-end;
	margin-bottom: 15px;

	Button {
		border: 1px solid #00BE94;
	}
}

.prof-name-title {
	margin-bottom: 8px;
}

.profiles-plugin-slate {
	position: relative;
	margin: 1em;
}

.edt-img-action {
	position: relative;
	top: 0;
	right: 0;
	bottom: 0;
	z-index: 10;

	.delete-img-btn{
		padding-top: 3px;
	}
}

.social-profile-box {
	display: flex;
	justify-content: center;
}

.social-profile-icons {
	font-size: 1.73em;
	justify-content: space-evenly;
	float: left;
	padding: 5px;
}

.edit-profile-icon {
	cursor: pointer;
	margin-right: 15px;
	height: 15px;
	width: 15px;
	&:hover{
		color: #00BE94;
	}
	&.danger{
		&:hover {
			color: #ea4d50;
		}
	}
}

.edit-profile-modal-icon {
	margin-left: 10px;
	cursor: pointer;
	&:hover{
		color: #00BE94;
	}
	&.danger{
		&:hover {
			color: #ea4d50;
		}
	}

	&.selected{
		color: white;
		&:hover{
			color: #00BE94;
		}
		&.danger{
			&:hover {
				color: #ea4d50;
			}
		}
	}
}

.delete-profile-modal-body{
	p {
	 font-weight: 600;
	 font-size: 1.1em;
	}
	padding-top: 20px;
}

