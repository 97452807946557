@import "../../content/css/config.scss";

.previewer{
  
  .part{
    position: absolute;
    top: 30%;
    left: 0;
    right: 0;
  }

  .textbox{
    .print-wrapper{
      height: 100%;

      .center{
        vertical-align: middle;
      }

      .top{
        vertical-align: top;
      }

      .bottom{
        vertical-align: bottom;
      }
    }

  }


  .chp_bg{
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-size: contain;
    background-repeat: no-repeat;
    z-index: -1;
  }

}

.page-indicator-text {
  display: flex;
  justify-content: center;
}
