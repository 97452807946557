/*

    Atticus 3.0 

    New Index.scss that stores all css in one file

*/


@import "./config.scss";

@font-face {
  font-family: 'Nunito Sans';
  src: url(../fonts/NunitoSans_10pt-Regular.ttf);
}
@font-face {
  font-family: 'Nunito Sans';
  font-weight: 500;
  src: url(../fonts/NunitoSans_10pt-Medium.ttf);
}
@font-face {
  font-family: 'Nunito Sans';
  font-weight: 600;
  src: url(../fonts/NunitoSans_10pt-SemiBold.ttf);
}

/*

 Base

*/


html {
  font-size: $font-size;
}

html,
body {
  margin: 0;
  padding: 0;
  border: 0;
  height: 100vh;
  background: transparent;
  font-size: 100%;
  vertical-align: baseline;
  overflow: hidden;
  font-family: 'Nunito Sans';
}

input,
button {
  padding: 0
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

a {
  text-decoration: none;
  cursor: pointer;
}


/*

  Spacers, Dividers and Containers

*/


.inner {
  padding: 1.5em 2em;
  margin-top: 1em;
  margin-bottom: 1em;
}
.inner-xl {
  padding-top: 3em;
  padding-bottom: 3em;
}
.inner-l {
  padding-top: 2em;
  padding-bottom: 2em;
}
.inner-m {
  padding-top: 1.5em;
  padding-bottom: 1.5em;
}

.inner-s {
  padding-top: 1em;
  padding-bottom: 1em;
}

.inner-xs {
  padding-top: .5em;
  padding-bottom: .5em;
}

.tube {
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 1em;
}

.well {
  max-width: 800px;
  width: 100%;
}

.well-m {
  max-width: 800px;
  width: 100%;
  margin: auto;
}


.spacer {
  height: 1.5rem;
}

.s-divider {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}


.no-margin {
  margin: 0 !important;
}

.nospace {
  margin: 0;
  padding: 0;
}

.at-container {
  padding-right: 1.5em;
  padding-left: 1.5em;
  margin: 0;
}

.scroll-container {
  overflow-y: auto;
  overflow-x: hidden;
  &.padded {
    padding-bottom: 2em;
  }

  &::-webkit-scrollbar{
    width: 8px;
    background-color: transparent;
  }

  &:hover::-webkit-scrollbar{
    background-color: #E8E8E9;
    border-radius: 4px;
  }

  &:hover::-webkit-scrollbar-thumb {
    background-color: #3568BA;
    border-radius: 4px;
  }
}

/*

    Typography 

*/

.heading {
  line-height: 2rem;
  font-size: 2rem;
  letter-spacing: -0.05rem;
}
.subtext {
  color: $subtext-color;
}
.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.quote {
  font-style: oblique;
  color: $subheading-text-color;
  font-size: 1rem;
  padding: .5em;
}

.text-xs {
  font-size: $font-size-xs;
}
.text-sm {
  font-size: $font-size-sm;
}
.text-lg {
  font-size: $font-size-lg;
}
.text-xl {
  font-size: $font-size-xl;
}


/*

    Forms 

*/

.at-form-item  {
  margin-bottom: 1.5em;
  .ant-form-item-label {
    font-weight: 500;
    padding-bottom: 0;
  }
}


/* Input Field */
.at-form-input, .at-form-search, .at-form-password, .at-form-number {
  border: 1px solid $status-disabled;
  height: 40px;
  border-radius: 4px;
}

input.primary:focus {
  outline-color: $primary-bg-blue;
  border-color: $primary-bg-blue;
  box-shadow: none;
}

input.primary:hover {
  outline-color: $primary-bg-blue-tint;
  border-color: $primary-bg-blue-tint;
}


input.secondary:focus {
  outline-color: $primary-bg-mint;
  border-color: $primary-bg-mint;
  box-shadow: none;
}

input.secondary:hover {
  outline-color: $primary-bg-mint-tint;
  border-color: $primary-bg-mint-tint;
}

.at-form-select {
  &.secondary {
    &.ant-select-focused{
      .ant-select-selector{
        outline-color: $primary-bg-mint-tint !important;
        border-color: $primary-bg-mint-tint !important;
        box-shadow: none !important;
      }
    }
    .ant-select-selector:hover{
      outline-color: $primary-bg-mint-tint;
      border-color: $primary-bg-mint-tint;
    }
  }
}


.ant-input-affix-wrapper.at-form-input.secondary:focus {
  outline-color: $primary-bg-mint;
  border-color: $primary-bg-mint;
  box-shadow: none;
}

.ant-input-affix-wrapper.at-form-input.secondary:hover {
  outline-color: $primary-bg-mint-tint;
  border-color: $primary-bg-mint-tint;
}

.ant-input-affix-wrapper:focus, .ant-input-affix-wrapper-focused {
  box-shadow: none;
}

.ant-input-number-status-error {
  border-color: $status-error !important;
}

.ant-form-item-explain-error {
  color: $status-error;
}

/* Atticus Switch */

.custom-switch {
  &-primary {
    &.ant-switch {
      &-checked {
        background-color: $primary-bg-blue;
        border-color: $primary-bg-blue;
        color: $primary-bg-blue;
      }

      background-color: #74777a;
      border-color: #74777a;
      color: #74777a;
      width: 36px;

      .ant-switch-handle {
        transform: translateX(0); // Reset the horizontal positioning
      }
    }
  }

  &-secondary {
    &.ant-switch {
      &-checked {
        background-color: $primary-bg-mint;
        border-color: $primary-bg-mint;
        color: $primary-bg-mint;
      }

      background-color: #74777a;
      border-color: #74777a;
      color: #74777a;
      width: 36px;

      .ant-switch-handle {
        transform: translateX(0);
      }
    }
  }
}

/* Atticus Checkbox */

.custom-checkbox-secondary {
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: $primary-bg-mint;
    border-color: $primary-bg-mint;

    &:hover {
      background-color: $primary-bg-mint;
      border-color: $primary-bg-mint;
    }
  }
  &:hover {
    .ant-checkbox-inner {
      border-color: $primary-bg-mint;
    }
  }
  .ant-checkbox-checked {
    &:after {
      border: 1px solid $primary-bg-mint;
    }
  }

  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-checked .ant-checkbox-input:hover+.ant-checkbox-inner {
    border-color: $primary-bg-mint;
  }
}

/* Atticus Radio Button */

.at-radio-group{
  .ant-radio-button-wrapper{
    height: unset;
  }
  &.green{
    .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked){
      &:hover{
        color: $primary-bg-mint;
      }
      span:hover{
        color: $primary-bg-mint;
      }
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
      border-color: $primary-bg-mint;
      background-color: $primary-bg-mint;
      &:hover{
        background-color: $primary-btn-green-hover;
      }
      &::before{
        background-color: $primary-bg-mint;
      }
    }
  }
  &.grey{
    .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked){
      color: $primary-text-black
    }
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled){
      border-color: #d9d9d9;
      background-color: $primary-bg-grey;
      color: $primary-text-black;
      &:hover{
        background-color: $primary-btn-grey-hover;
      }
      &::before{
        background-color: $primary-bg-grey;
      }
    }
  }
}

/* Atticus Slider */

.custom-handle {
  width: 20px;
  height: 20px;
  background-color: #fff;
  border: 2px solid $primary-bg-blue;
  border-radius: 50%;
  color: $primary-bg-blue;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: grab;
  font-weight: bold;
}

.at-slider {
  .ant-slider-handle {
    &.ant-slider-handle {
      width: 14px;
      height: 14px;
      border: 4px solid $primary-bg-blue;
      background-color: #fff;
      border-radius: 50%;
      color: $primary-bg-blue;
      cursor: grab;
      font-weight: bold;
    }
  }

  .ant-slider-track {
    background-color: $primary-bg-blue;
  }

  .ant-slider-dot-active {
    display: none;
    border-color: $primary-bg-blue;
  }

  &.at-slider-contained {
    .ant-slider-mark {
      .ant-slider-mark-text:first-child {
        transform: translateX(0%)!important;
      }

      .ant-slider-mark-text:last-child {
        transform: translateX(-100%)!important;
      }
    }
  }
}

.at-slider-green {
  .ant-slider-handle {
    &.ant-slider-handle {
      width: 14px;
      height: 14px;
      border: 4px solid $primary-bg-mint;
      background-color: #fff;
      border-radius: 50%;
      color: $primary-bg-mint;
      cursor: grab;
      font-weight: bold;
    }
  }

  .ant-slider-track {
    background-color: $primary-bg-mint;
  }

  .ant-slider-dot-active {
    display: none;
    border-color: $primary-bg-mint;
  }

  .ant-slider-rail {
    &.ant-slider-rail {
      background-color: $primary-bg-mint-tint-2;
    }
  }

  .ant-slider-track {
    &.ant-slider-track {
      background-color: $primary-bg-mint;
    }
  }
}

.at-slider-green:hover {
  .ant-slider-handle:not(.ant-tooltip-open) {
    border-color: $primary-bg-mint-tint;
  }
}

/* Atticus Select */

.at-form-select {
  .ant-select-selector {
    display: flex;
    align-items: center;
    height: min-content!important;
    min-height: 40px!important;
    border-radius: 4px!important;
  }
  
  &.at-form-single{
    .ant-select-selection-item {
      padding-right: 2em;
    }
  }
} 

.at-form-select-dropdown  {
  padding: .125em ;
  margin-bottom: .125em;

  .ant-select-item  {
    border-radius: 4px;
  }
}

/* Atticus Date Picker */
.ant-picker-dropdown.at-date-picker .ant-picker-header > button {
  color: $primary-bg-mint;
}

.ant-picker-dropdown.at-date-picker .ant-picker-cell .ant-picker-cell-inner {
  border-radius: 50%;
  
}

.ant-picker-dropdown.at-date-picker .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border-radius: 50%;
  border: 1px solid $primary-bg-mint;
}

.ant-picker-dropdown.at-date-picker .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, 
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, 
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background-color: $primary-bg-mint;
}

/* Atticus Horizontal Tab */
.at-tabs {
  .at-tabs-badge {
    display: inline-block;
    padding: 0.5em 0.25em;
    margin-left: 0.5em;
    background-color: white;
    color: black;
    border-radius: 5px;
    line-height: 0.5;
    border: 1px solid #ccc;

    &:hover {
      background-color: #b9c9ea;
      color: #fff;
    }
  }
  
  .ant-tabs-tab:hover {
    .at-tabs-badge {
      background-color: #e7edf8;
      color: $primary-bg-blue;
    }
  }

  .ant-tabs-tab-active {
    .at-tabs-badge {
      background-color: #e7edf8;
      color: $primary-bg-blue;
      border: none;
    }
  }

  .ant-tabs-ink-bar {
    background-color: $primary-bg-blue;
  }

  .ant-tabs-nav {
    &::before {
      border-bottom: 2px solid #D4E0F1;
    }

    .ant-tabs-ink-bar {
      height: 3px;
    }
  }
}

.at-vertical-tabs {
  position: relative;
  align-items: stretch;
  height: 100%;
  
  .ant-tabs-content-holder {
    display: flex;
  }

  .ant-tabs-nav-list {
    padding: 0;

    .ant-tabs-tab {
      padding: 0 1.5em!important;
      margin: 0!important;
    }
    .ant-tabs-tab-btn {
      width: 100%;
      padding: .75em 1em;
      text-align: left;
      border-radius: 4px;
      border-left: none;
    }
  
    .ant-tabs-tab-active {
      .ant-tabs-tab-btn {
        background-color: #F3F3F3;
      }
    }
  }
  .ant-tabs-tabpane {
    background-color: $primary-background;
    border-radius: 4px;
    padding: 1em;
    height: 100%;
    overflow: auto;
    margin-right: 1em;

    &::-webkit-scrollbar{
      width: 8px;
      background-color: transparent;
    }
  
    &:hover::-webkit-scrollbar{
      background-color: #E8E8E9;
      border-radius: 4px;
    }
  
    &:hover::-webkit-scrollbar-thumb {
      background-color: #3568BA;
      border-radius: 4px;
    }
  }
}

/* Atticus Veritcal Menu */

.custom-menu-class {
  width: 176px;
  height: 40px;
}

.custom-menu-class .ant-menu-item-selected {
  background-color: #f3f3f3 !important;
  color: $primary-bg-blue;
  border-radius: 4px;
}

.custom-menu-class .ant-menu-item {
  border-right: none;
}

.custom-menu-class .ant-menu-item {
  padding-right: 0;
}


/*

    Buttons 

*/

.atticus-button {
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border-width: 0.10rem;
  font-weight: 500;

  &.full-with {
    width: 100%;
  }

  .atticus-button-text {
    margin-right: 0.5em;
    margin-left: 0.5em;
  }
  
  /* Primary button styles */
  &.at-primary {
    
    svg {
      color: #ffffff;
    }

    &.blue {
      background-color: $primary-bg-blue;
      border-color: $primary-bg-blue;
      color: #ffffff;

      &:hover {
        background-color: $primary-btn-blue-hover;
        border-color: $primary-btn-blue-hover;
      }

      &:active {
        background-color: $primary-btn-blue-clicked;
        border-color: $primary-btn-blue-clicked;
      }
    }

    &.green {
      background-color: $primary-bg-mint;
      border-color: $primary-bg-mint;
      color: #ffffff;

      &:hover {
        background-color: $primary-btn-green-hover;
        border-color: $primary-btn-green-hover;
      }

      &:active {
        background-color: $primary-btn-green-clicked;
        border-color: $primary-btn-green-clicked;
      }
    }

    &.ant-btn-dangerous {
      background-color: #fe2121;
      border-color: #fe2121;
      color: #ffffff;
    }

    &.btn-disabled {
      background-color: #e8e8e9;
      border-color: #e8e8e9;
      color: #ffffff;

      &:hover {
        @extend .btn-disabled;
      }
    }
  }

  /* Secondary button styles */
  &.at-secondary {

    &.blue {
      color: $primary-bg-blue;
      border-color: $primary-bg-blue;

      svg {
        color: $primary-bg-blue;
      }
      
      &:hover {
        background-color: $secondary-btn-blue-hover;
        border-color: $primary-btn-blue-hover;
        color: $primary-btn-blue-hover;
      }

      &:active {
        background-color: $secondary-btn-blue-clicked;
        border-color: $primary-btn-blue-clicked;
        color: $primary-btn-blue-clicked;
      }
    }

    &.green {
      color: $primary-bg-mint;
      border-color: $primary-bg-mint;

      svg {
        color: $primary-bg-mint;
      }

      &:hover {
        background-color: $secondary-btn-green-hover;
        border-color: $primary-btn-green-hover;
        color: $primary-btn-green-hover;
      }

      &:active {
        background-color: $secondary-btn-green-clicked;
        border-color: $primary-btn-green-clicked;
        color: $primary-btn-green-clicked;
      }
    }

    &.white {
      color: $primary-text-black;
      border-color: #d9d9d9;

      svg {
        color: $primary-text-black;
      }

      &:hover {
        background-color: $primary-btn-grey-hover;
        border-color: #d9d9d9;
      }

      &:active {
        background-color: $primary-btn-grey-hover;
        border-color: #d9d9d9;
      }
    }

    &.btn-disabled {
      background-color: #ffffff;
      border-color: #e8e8e9;
      color: #e8e8e9;

      &:hover {
        @extend .btn-disabled;
      }
    }
  }

  /* Ghost button styles */
  &.at-ghost {
    &.blue {
      color: $primary-bg-blue;

      &:hover {
        color: $primary-btn-blue-hover;
      }

      &:active {
        color: $primary-btn-blue-clicked;
      }
    }

    &.green {
      color: $primary-bg-mint;

      &:hover {
        color: $primary-btn-green-hover;
      }

      &:active {
        color: $primary-btn-green-clicked;
      }
    }

    &.btn-disabled {
      color: #e8e8e9;

      &:hover {
        @extend .btn-disabled;
      }
    }
  }

  svg {
    vertical-align: text-bottom;
  }

  &.reverse {
    flex-flow: row-reverse;
  }
  
  /* Styles based on size */
  &.icon-only {
    padding: 0.313em;
  }
  &.small {
    height: $btn-size-small;
  }

  &.medium {
    height: $btn-size-medium;
  }

  &.large {
    height: $btn-size-large;
  }

  &.xlarge {
    height: $btn-size-xlarge;
  }
  
  .ant-btn-loading-icon {
    display: flex;
    align-items: center;
  }
}

.home-page-section-heading{
  font-size: 16px;
  color: #171D21;
  font-weight: 600;
}

.book-action-section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 30px;

  .book-action-section-left{
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 30px;
  }
  .book-action-item {
    display: flex;
    flex-direction: column;
    // height, width values below are calculated as percentages the dimensions of 
    // the sorrounding elements according to the UI design
    width: 19.152vw;
    height: 10.566vw;
    max-width: 307.8px;
    max-height: 169.82px;
    min-width: 232.56px;
    min-height: 128.31px;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    background-color: $primary-bg-blue;
    cursor: pointer;
    &:hover {
      background-color: $primary-btn-blue-hover;
      border-color: $primary-btn-blue-hover;
    }

    &:active {
      background-color: $primary-btn-blue-clicked;
      border-color: $primary-btn-blue-clicked;
    }
    .book-action-label{
      font-size: 14px;
      font-weight: 600;
      color: #ffffff;
    }

    &.disabled {
      cursor: not-allowed;
      background-color: rgb(53 104 186 / 50%);
    }
  }

  .book-action-tutorial-card {
    display: flex;
    flex-direction: row;
    width: 31.6vw;
    height: 10.566vw;
    max-width: 507.87px;
    max-height: 169.82px;
    min-width: 383.724px;
    min-height: 128.31px;
    background-color: white;
    padding: 0.8rem;
    border: 1px solid #E8E8E9;
    border-radius: 6px;

    .tutorial-card-left{
      flex: 1.4;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-right: 5px;
      .left-txt-line-1{
        font-size: 14px;
        font-weight: 600;
        line-height: 1.4;
        color: #171D21;
      }
      .left-txt-line-2 {
        font-size: 10px;
        color: #a2a5a6;
      }
      .text-and-icon-link{
        font-size: 12px;
      }
    }
    .tutorial-card-right{
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: $primary-bg-mint-tint-2;
    }
  }
}
// Add Card Buttons

.at-add-card-btn {
  height: 220px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 0.5em;
  font-size: 1.125em;
  padding: 2em;
  text-wrap: wrap;
}

/*

    Cards

*/
// Book Card View styles
.book-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;
  &.single-row {
    overflow: hidden;
    height: 22.4vw;
    max-height: 360px;
    min-height: 272px;
  }
  &.no-data {
    justify-content: center;
  }

  .loading-book-item {
    pointer-events: none;
    cursor:not-allowed;
  }

  .book-item {
    width: 14vw;
    height: 22.4vw;
    max-width: 225px;
    max-height: 360px;
    min-width: 170px;
    min-height: 272px;
    padding: 7.547%;
    box-sizing: border-box;
    border: 1px solid #dbdcdd;
    background: #ffffff;
    border-radius: 6px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    cursor: pointer;

    &.create-button-item{
      justify-content: center;
      align-items: center;
      padding: 1em;
      background: $primary-bg-blue;
      border-radius: 5px;
      cursor: pointer;
      &:hover{
        background-color: $primary-bg-blue-tint;
      }
      .create-button-icon{
        transform: scale(1.5);
        margin-bottom: 0.5em;
      }
      .create-button-label{
        color: $default-bg-color;
        font-size: 14px;
      }
    }

    &:hover {
      background: #f1f5fc;
      box-shadow: -2px 8px 28px #171d210a;
      border: 1px solid #dbdcdd;
    }

    .book-card-img-wrapper {
      height: 14.528vw;
      max-height: 233.4857px;
      min-height: 176.4113px;
      width: 100%;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      background-color: #D4E0F1;
      .book-card-icon {
        transform: scale(1.5);
      }
    }
  }


  .book-card-content {
    text-align: left;
    // background-color: burlywood;

    .book-title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 14px;
      font-weight: 600;
      color: #171D21;
      line-height: 1;
    }

    /* Boxset label styles */
    .boxset-lable {
      color: #ffffff;
      background-color: #83a3d5;
      border-radius: 2px;
      text-align: center;
      font-size: 12px;
      margin: 2px 0px 5px 0px;
    }

    /* Books count label styles */
    .books-count-lable {
      color: #ffffff;
      background-color: #83a3d5;
      border-radius: 2px;
      text-align: center;
      font-size: 12px;
      margin: 2px 0px 5px 0px;
    }

    /* Author name styles */
    .author-title {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 12px;
      font-weight: 400;
      color: #454A4D;
      line-height: 1;
      margin-top: 8px;
    }

    /* Book Card Action Button div styles */
    .book-card-action {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      margin-top: 2px;

      .book-date {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 10px;
        font-weight: 400;
        color: #a2a5a6;
        line-height: 1;
        margin-top: 8px;
      }
      .book-card-dropdown{
        height: 18px;
      }
      .book-card-dropdown-icon{
        scale: 80%;
        background-size: 10px 10px;
      }
    }
  }
}

// Change card text size between the min max values with screen re-size
@media (max-width: 1213px) {
  .home-page-section-heading{
    font-size: 16px;
  }
  .book-action-section {
    .book-action-item {
      .book-action-label{
        font-size: 14px;
      }
    }
    .book-action-tutorial-card{
      .tutorial-card-left{
        .left-txt-line-1{
          font-size: 14px;
        }
        .left-txt-line-2{
          font-size: 10px;
        }
        .text-and-icon-link{
          font-size: 12px;
        }
      }
    }
  }
  .book-list {
    .book-item {
      &.create-button-item{
        .create-button-label{
          font-size: 14px;
        }
      }
      .book-card-content{
        .book-title {
          font-size: 14px;
        }
        .author-title {
          font-size: 12px;
        }
        .book-card-action {
          .book-date {
            font-size: 10px;
          }
        }
      }
    }
  }
}

@media (min-width: 1214px) and (max-width: 1311px) {
  .home-page-section-heading{
    font-size: 17px;
  }
  .book-action-section {
    .book-action-item {
      .book-action-label{
        font-size: 15px;
      }
    }
    .book-action-tutorial-card{
      .tutorial-card-left{
        .left-txt-line-1{
          font-size: 15px;
        }
        .left-txt-line-2{
          font-size: 11px;
        }
        .text-and-icon-link{
          font-size: 13px;
        }
      }
    }
  }
  .book-list {
    .book-item {
      &.create-button-item{
        .create-button-label{
          font-size: 15px;
        }
      }
      .book-card-content{
        .book-title {
          font-size: 15px;
        }
        .author-title {
          font-size: 13px;
        }
        .book-card-action {
          .book-date {
            font-size: 11px;
          }
        }
      }
    }
  }
}

@media (min-width: 1312px) and (max-width: 1409px) {
  .home-page-section-heading{
    font-size: 18px;
  }
  .book-action-section {
    .book-action-item {
      .book-action-label{
        font-size: 16px;
      }
    }
    .book-action-tutorial-card{
      .tutorial-card-left{
        .left-txt-line-1{
          font-size: 16px;
        }
        .left-txt-line-2{
          font-size: 12px;
        }
        .text-and-icon-link{
          font-size: 14px;
        }
      }
    }
  }
  .book-list {
    .book-item {
      &.create-button-item{
        .create-button-label{
          font-size: 16px;
        }
      }
      .book-card-content{
        .book-title {
          font-size: 16px;
        }
        .author-title {
          font-size: 14px;
        }
        .book-card-action {
          .book-date {
            font-size: 12px;
          }
        }
      }
    }
  }
}

@media (min-width: 1410px) and (max-width: 1507px) {
  .home-page-section-heading{
    font-size: 19px;
  }
  .book-action-section {
    .book-action-item {
      .book-action-label{
        font-size: 17px;
      }
    }
    .book-action-tutorial-card{
      .tutorial-card-left{
        .left-txt-line-1{
          font-size: 17px;
        }
        .left-txt-line-2{
          font-size: 13px;
        }
        .text-and-icon-link{
          font-size: 15px;
        }
      }
    }
  }
  .book-list {
    .book-item {
      &.create-button-item{
        .create-button-label{
          font-size: 17px;
        }
      }
      .book-card-content{
        .book-title {
          font-size: 17px;
        }
        .author-title {
          font-size: 15px;
        }
        .book-card-action {
          .book-date {
            font-size: 13px;
          }
        }
      }
    }
  }
}

@media (min-width: 1508px) and (max-width: 1605px) {
  .home-page-section-heading{
    font-size: 20px;
  }
  .book-action-section {
    .book-action-item {
      .book-action-label{
        font-size: 18px;
      }
    }
    .book-action-tutorial-card{
      .tutorial-card-left{
        .left-txt-line-1{
          font-size: 18px;
        }
        .left-txt-line-2{
          font-size: 14px;
        }
        .text-and-icon-link{
          font-size: 16px;
        }
      }
    }
  }
  .book-list {
    .book-item {
      &.create-button-item{
        .create-button-label{
          font-size: 18px;
        }
      }
      .book-card-content{
        .book-title {
          font-size: 18px;
        }
        .author-title {
          font-size: 16px;
        }
        .book-card-action {
          .book-date {
            font-size: 14px;
          }
        }
      }
    }
  }
}

@media (min-width: 1606px) {
  .home-page-section-heading{
    font-size: 21px;
  }
  .book-action-section {
    .book-action-item {
      .book-action-label{
        font-size: 19px;
      }
    }
    .book-action-tutorial-card{
      .tutorial-card-left{
        .left-txt-line-1{
          font-size: 19px;
        }
        .left-txt-line-2{
          font-size: 15px;
        }
        .text-and-icon-link{
          font-size: 17px;
        }
      }
    }
  }
  .book-list {
    .book-item {
      &.create-button-item{
        .create-button-label{
          font-size: 19px;
        }
      }
      .book-card-content{
        .book-title {
          font-size: 19px;
        }
        .author-title {
          font-size: 17px;
        }
        .book-card-action {
          .book-date {
            font-size: 15px;
          }
        }
      }
    }
  }
}

/* Book Card Content styles */


// Theme card styles

.theme-card-view {
  width: 178px;
  height: 246px;
  background-color: transparent;

  .theme-card-view-image {
    position: relative;
    width: 178px;
    height: 220px;
    background: transparent 0% 0% no-repeat padding-box;
    border: 1px solid #dbdcdd;
    border-radius: 8px;
    margin-bottom: 8px;
    overflow: hidden;
    transition: background-color 0.3s ease;

    /* Theme card hover styles */
    &:hover {
      background-color: rgba(23, 29, 33, 0.7);

      .action-buttons {
        opacity: 1;
        visibility: visible;
      }

      .btn-theme-favourite {
        border: none !important;
      }
    }

    /* Favourite button styles */
    .favourite-button-div {
      position: absolute;
      bottom: 0;
      right: 0;
      margin: 12px;

      .btn-theme-favourite {
        z-index: 1;
        width: 24px;
        height: 24px;
        box-shadow: 0px 1px 12px #00000014;
        background-color: transparent;
        border: 1px solid #dbdcdd;
      }
    }

    /* Them card action buttons area */
    .action-buttons {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      opacity: 0;
      visibility: hidden;
      transition: opacity 0.3s ease;

      .theme-edite-button {
        width: 138px;
        margin-bottom: 16px;
        font-size: 16px;
        font-weight: 600;
        border: none;
        opacity: 1;
      }

      .theme-duplicate-button {
        width: 138px;
        background-color: #e7edf8;
        font-size: 16px;
        font-weight: 600;
        opacity: 1;
      }
    }
  }

  /* Theme card title styles */
  .theme-card-view-title {
    width: 178px;
    display: flex;
    justify-content: center;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0px;
    color: #171d21;
    opacity: 1;
  }
}


// Link Styles

.text-and-icon-link {
  display: flex;
  color: $primary-bg-mint;
  font-size: 16px;
  font-weight: 600;

  &:hover {
    color: $primary-bg-mint;
  }
}

.text-and-icon-container {
  display: flex;
  align-items: center;
}

/*  Modal  */

.at-modal {
  .ant-modal-content {
    padding: 1.5em 2rem;
  }
  .ant-modal-header {
    padding: 0;
    background-color: transparent;
    position: relative;
    z-index: 10;
  }
  .ant-modal-title {
    padding: 0;

    .at-modal-header {
      padding-bottom: 1em;

      .at-modal-title {
        font-size: 1.25rem;
        .warning-modal-title{
          color: #fe2121;
        }
      }
    }
  }

  .ant-modal-body {
    padding: 1em 0;
  }
  
  .at-modal-close {
    button {
      all: unset;
      cursor: pointer;
    }
  }

  .ant-modal-footer {
    border: none;
    padding: 0;

    .at-modal-footer {  
      padding-bottom: .5em;
      
      .atticus-button {
        width: 100%;
        border-radius: .25rem;
      }
    }
  }
}

.at-dialog {
  min-width: $modal-width-dialog !important;
  .at-modal-close {
    button {
      all: unset;
      cursor: pointer;
    }
  }
  .ant-modal-body{
    padding: 1.5em 2rem;
    .ant-modal-confirm-body > [role="img"]:first-child {
      display: none;
    }
    .ant-modal-confirm-body {
      .ant-modal-confirm-title{
        padding-bottom: 1em;
        border-bottom: 1px solid #f0f0f0;
        border-radius: 2px 2px 0 0;
        color: #171d21;
        .at-modal-header{
          .at-modal-title{
            font-size: 1.25rem;
          }
        }
      }
      .ant-modal-confirm-content{
        margin-top: unset !important;
        margin-left: unset !important;
        .at-dialog-content{
          padding: 1em 0;
        }
      }
    }
    .ant-modal-confirm-btns{
      display: none;
    }
  }
}

/*
  
Breadcrumbs

*/
.at-breadcrumbs {
  li:not(:last-child) {
    cursor: pointer;
  }
  svg {
    vertical-align: text-bottom;
  }
}

/* 

  Image Gallery  

*/

.image-gallery-wrapper {
  width: 18vw;
  height: 10.6vw;
  min-width: 270px;
  min-height: calc((10.6 / 18) * 270px);
  display: flex;
  flex-direction: column;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 15px;
  .image-gallery-image-wrapper{
    height: 80%;
    cursor: pointer;
    .image-gallery-image{
      height: 100%;
      width: 100%;
      object-fit: cover;
      border-radius: 5px;
      &.contain {
        object-fit: contain;
        background-color: #eeeeee;
        padding: 5px;
      }
    }
  }
  .image-gallery-info-wrapper{
    height: 20%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .image-gallery-image-name-wrapper {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      .image-gallery-image-name {
        font-size: 14px;
        color: #171D21;
      }
    }
    .book-card-option-button {
      padding: 0px !important;
      margin: 0px !important;
    }
  }
}

.image-gallery-tab-wrapper{
  height: 62vh;
  background-color: #F7F7F7;
  .image-gallery-tab-inner{
    max-height: 62vh;
    min-height: 300px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-evenly;
    overflow-y: auto;
  }
}
.delete-from-gallery-btn{
  .ant-dropdown-menu-title-content{
    padding-left: 0.5rem;
  }
}

/* Editor toolbar 'Add an Image' modal styles */

.image-toolbar-body-wrapper{
  display: flex;
  flex-direction: column;

  .image-toolbar-divider-text {
    color: #A2A5A6;
  }
  .image-toolbar-upload-header{
    align-self: center;
    font-size: 1.1rem;
    color: #171D21;
    margin-bottom: 15px;
  }
  .image-toolbar-dnd-container{
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
   .image-toolbar-dnd-text-highlight{
    color: #3363B9;
   }
  }
  .image-toolbar-upload-footer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    .image-toolbar-upload-footer-text{
      font-size: 0.8rem;
      color: #454A4D;
    }
  }
  .image-toolbar-image-previewer{
    width: 100%;
    height: 150px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 4px;
    margin-bottom: 20px;
    background-color: #E7EDF8;

    &.contain {
      background-size: contain;
    }

    .image-toolbar-image-previewer-container{
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;
      flex-direction: column;
      padding: 0.75em 0.5em; 
      border-radius: 4px;
    }

    .image-toolbar-image-previewer-name{
      size: 0.8rem;
      color: white;
      margin-bottom: 15px;
    }
  }
  .image-toolbar-ant-form {
    .ant-form-item-label{
      padding: 0px;
    }
    .edit-image-form-label{
      color: #171D21;
      font-size: 0.9rem;
      font-weight: 600;
    }
    .edit-image-form-input{
      border-radius: 4px;
    }
    .anticon {
      display: inline-block;
    }
    .image-link-button{
      padding-top: 5px;
      padding-bottom: 5px;
    }
    .image-flow-button {
      padding-top: 7px;
      padding-bottom: 3px;
      .anticon{
        font-size: 1.4em;
      }
    }
    .image-flow-left-align-button{
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    .image-flow-right-align-button{
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    .image-link-left-align-button{
      border-top-left-radius: 4px;
      border-bottom-left-radius: 4px;
    }
    .image-link-right-align-button{
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
    }
    .image-toolbar-upload-form-item{
      margin-bottom: 5px;
    }
  }
  .edit-image-width-wrapper{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    .edit-image-width-slider{
      width: 90%;
    }
  }
}

.antd-full-width-radio-group{
  width: 100%;
  display: flex;
  .ant-radio-button-wrapper{
    flex: 1;
    display: flex;
    justify-content: center;
  }
}

/* Sidebar import chapters modal styles */
.import-chapter-modal-wrap{
  .at-modal-title{
    font-weight: 600;
  }
  .import-chapter-dnd-container{
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .import-chapter-dnd-text-highlight{
      color: #3363B9;
    }
  }
}


/* Media queries to make the modal width dynamic */

/* Extra small devices (phones) */
@media only screen and (max-width: 575.98px) {
  .image-toolbar-modal-wrap {
    .ant-modal{
      width: 90vw !important;
    }
  }
}

/* Small devices (tablets) */
@media only screen and (min-width: 576px) and (max-width: 767.98px) {
  .image-toolbar-modal-wrap {
    .ant-modal{
      width: 80vw !important;
    }
  }
}

/* Medium devices (desktops) */
@media only screen and (min-width: 768px) and (max-width: 991.98px) {
  .image-toolbar-modal-wrap {
    .ant-modal{
      width: 60vw !important;
    }
  }
}

/* Large devices (desktops) */
@media only screen and (min-width: 992px) and (max-width: 1199.98px) {
  .image-toolbar-modal-wrap {
    .ant-modal{
      width: 50vw !important;
    }
  }
}

/* Extra large devices (large desktops) */
@media only screen and (min-width: 1200px) and (max-width: 1499.98px) {
  .image-toolbar-modal-wrap {
    .ant-modal{
      width: 45vw !important;
    }
  }
}

@media only screen and (min-width: 1500px) and (max-width: 1699.98px){
  .image-toolbar-modal-wrap {
    .ant-modal{
      width: 35vw !important;
    }
  }
}

@media only screen and (min-width: 1700px) {
  .image-toolbar-modal-wrap {
    .ant-modal{
      width: 30vw !important;
      max-width: 600px;
    }
  }
}