.editor-text-message {
  width: 75%;
  .message {
    background-color: whitesmoke;
    padding: 1em 3em 1em 3em;
    border: 1px solid rgb(227, 227, 227);
    border-radius: 30px;
    margin-bottom: 1em;
    width: 100%;

    .message-content {
      display: inline-block;
      flex-grow: 1;
      align-items: center;
      padding-top: 3px;
      width: min-content;
      word-break: break-word;
    }
  }

  .sent {
    margin-left: 8em;
    margin-right: -8em;
    background-color: rgb(234, 240, 252);
    border: 1px solid rgb(218, 228, 250);

  }

  .contact, .contact-received, .contact-contact {
    background-color: rgb(246, 246, 246);
    border: unset;
    border-radius: unset;
  }

  .contact-sent {
    background-color: rgb(246, 246, 246);
    border: unset;
    border-radius: unset;
    margin-left: 8em;
    margin-right: -8em;
  }

  .editor-text-message-action {
    z-index: 10;
    height: max-content;

    .editor-text-message-action-btn {
      height: 2em;
      width: 2em;
      padding: 0!important;
      margin-left: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}

.plate-custom-text-messages-block{
  padding: 1em 5em 0 5em;
  position: relative;
  width: 50%;
  min-width: 600px;
  margin-bottom: 2em;
}
.plate-custom-text-messages-block:after,
.plate-custom-text-messages-block:before {
  border: .2em solid rgb(153, 168, 201); 
  bottom: 0;    
  content: '';
  position: absolute;
  top: 0;
  width: 2%;
}
.plate-custom-text-messages-block:after {
  border-left: none;
  right: 0;
}
.plate-custom-text-messages-block:before {
  border-right: none;
  left: 0;
}

.text-block-action {
  top: 5px;
  right: 5px;
  bottom: 0;
  z-index: 10;

  .text-block-action-btn {
    height: 2em;
    width: 2em;
    padding: 0!important;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.text-block-menu {
  display: none;
}
.ant-dropdown-menu-item-selected {
  background: none !important;

  .text-settings-selected-icon {
    margin-left: 5px;
    display: inline-block;
  }
}