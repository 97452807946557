.inter {
  height: 100%;
}

.p-device {
  position: relative;

  img {
    position: relative;
    z-index: 10;
    pointer-events: none;
  }
  .inside {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 0;
    background-color: white;
    overflow-y: auto;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none;
    
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
.ipad {
  .inside {
    padding: 2.5em 2em;
    margin-top: 40px;
    margin-bottom: 53px;
    margin-right: 52px;
    margin-left: 40px;
  }
}
.iphone {
  .inside {
    padding: 3em 2em;
    margin-top: 40px;
    margin-bottom: 60px;
    margin-right: 62px;
    margin-left: 45px;
  }
}
.galaxy_s9 {
  .inside {
    padding: 2em;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-right: 20px;
    margin-left: 20px;
  }
}
.galaxy_s21 {
  .inside {
    padding: 3em 2em 2em;
    margin-top: 35px;
    margin-bottom: 55px;
    margin-right: 55px;
    margin-left: 40px;
  }
}
.galaxy_tab_7 {
  .inside {
    padding: 2em;
    margin-top: 42px;
    margin-bottom: 60px;
    margin-right: 60px;
    margin-left: 45px;
  }
}
.fire {
  .inside {
    padding: 2em;
    margin-top: 88px;
    margin-bottom: 98px;
    margin-right: 88px;
    margin-left: 70px;
  }
}
.paperwhite {
  .inside {
    padding: 2em 1.5em;
    margin-top: 85px;
    margin-bottom: 120px;
    margin-right: 80px;
    margin-left: 66px;
    background-color: #d9e0db;
    & > div {
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      -ms-filter: grayscale(100%);
      -o-filter: grayscale(100%);
    }
  }
  .chapter {
    opacity: 0.8;
  }
}
.forma {
  .inside {
    padding: 2em;
    margin-top: 38px;
    margin-bottom: 48px;
    margin-right: 48px;
    margin-left: 95px;
    background-color: #f3f7f5;
  }
}
.libra {
  .inside {
    padding: 1.5em 2em;
    margin-top: 40px;
    margin-bottom: 52px;
    margin-right: 105px;
    margin-left: 40px;
    background-color: #f3f7f5;
  }
}
.clara {
  .inside {
    padding: 2em;
    margin-top: 60px;
    margin-bottom: 115px;
    margin-right: 70px;
    margin-left: 55px;
    background-color: #d9e0db;
    & > div {
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      -ms-filter: grayscale(100%);
      -o-filter: grayscale(100%);
    }
  }
  .chapter {
    opacity: 0.8;
  }
}
.nia {
  .inside {
    padding: 2em;
    margin-top: 68px;
    margin-bottom: 112px;
    margin-right: 72px;
    margin-left: 56px;
    background-color: #f3f7f5;
  }
}
.oasis {
  .inside {
    padding: 2em;
    margin-top: 42px;
    margin-bottom: 55px;
    margin-right: 100px;
    margin-left: 42px;
    background-color: #d9e0db;

    & > div {
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      -ms-filter: grayscale(100%);
      -o-filter: grayscale(100%);
    }
  }
  .chapter {
    opacity: 0.8;
  }
}
.glowlight {
  .inside {
    padding: 2em;
    margin-top: 84px;
    margin-bottom: 98px;
    margin-right: 85px;
    margin-left: 74px;
    background-color: #d9e0db;
    & > div {
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      -ms-filter: grayscale(100%);
      -o-filter: grayscale(100%);
    }
  }
  .chapter {
    opacity: 0.8;
  }
}
.glowlight_plus {
  .inside {
    padding: 2em;
    margin-top: 88px;
    margin-bottom: 100px;
    margin-right: 75px;
    margin-left: 62px;
    background-color: #d9e0db;
    & > div {
      -webkit-filter: grayscale(100%);
      -moz-filter: grayscale(100%);
      -ms-filter: grayscale(100%);
      -o-filter: grayscale(100%);
    }
  }
  .chapter {
    opacity: 0.8;
  }
}
