@import '../../content/css/config.scss';

$default-padding: 0.8em 1.4em;
$default-box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.05);

.sprint-timer-setting-toolbar {
    max-height: 41px;
    background-color: #ffffff;
    border-radius: 5px;
    height: 40px;
    display: flex;
    // padding-right: 15px;
    // padding-left: 10px;
    margin-left: 10px;
    cursor: pointer;

  }

.sprint-timer-stat {
    display: flex;
    align-items: "center";
    padding: 6px;
    margin-top: 4px;
}

.before-sprint{
    display: flex;
    gap: 5px;
}

.after-sprint {
    display: flex;
    gap: 5px;
    svg {
        filter: invert(50%) sepia(91%) saturate(1111%) hue-rotate(127deg) brightness(92%) contrast(101%)
    }
}

.sprint-break {
    display: flex;
    gap: 5px;
    svg {
        filter: invert(34%) sepia(98%) saturate(482%) hue-rotate(180deg) brightness(92%) contrast(91%);
    }
}

.sprint-input-field {
  width: 100% !important;
  height: 40px !important;
  background-color: #f7f7f7 !important;
}

.sprint-toolbar-label {
  padding-left: 2px;
}
